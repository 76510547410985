* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: #eceff1;
}

a {
  color: inherit;
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}